import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="footer-intro">
              <h3>BhimGurung</h3>
              <p>
                I am Bhim Gurung, a professional Co-operator from Nepal. I’ve been serving as the CEO of Pokhara Royal Co-operative for the last two decades. As a certified motiv....
              </p>
            </div>
            <div className="footer-social-media">
              <a href="https://www.facebook.com/bhim.gurung.549668" target="_blank" rel="noreferrer">
                <div className="footer-social-media-wrapper">
                  <i className="fab fa-facebook-f"></i>
                </div>
              </a>
              <a href="https://www.instagram.com/bhimgurung777/" target="_blank" rel="noreferrer">
                <div className="footer-social-media-wrapper">
                  <i className="fab fa-instagram"></i>
                </div>
              </a>
              <a href="https://www.gmail.com/">
                <div className="footer-social-media-wrapper" target="_blank" rel="noreferrer">
                  <i className="fa fa-envelope"></i>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-header">
              <h4>CONTACT ME</h4>
            </div>
            <div className="quick-link footer-contact">
              <ul>
                <li><a href="/#"><i className="fa fa-phone"></i> 9856020878/9856081510</a></li>
                <li><a href="/#"><i className="fa fa-envelope"></i> bhimgrg2030@gmail.com</a></li>
                <li><a href="/#"><i className="fa fa-globe"></i> bhimgrg.com</a></li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-header">
              <h4>QUICK LINK</h4>
            </div>
            <div className="quick-link">
              <ul>
                <li><Link to="/"><i className="fa fa-angle-right"></i> Home</Link></li>
                <li><Link to="/about"><i className="fa fa-angle-right"></i> About</Link></li>
                <li><Link to="/blog"><i className="fa fa-angle-right"></i> Blog</Link></li>
                <li><Link to="/contact"><i className="fa fa-angle-right"></i> Contact</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right">
        <p>Bhim Gurung © 2023. All Rights Reserved</p>
      </div>
    </section>
  )
}

export default Footer;