import {
    CREATE_CONTACT_REQUEST,
    CREATE_CONTACT_SUCCESS,
    CREATE_CONTACT_FAIL,
    GET_CONTACT_REQUEST,
    GET_CONTACT_SUCCESS,
    GET_CONTACT_FAIL,
    DETAIL_CONTACT_REQUEST,
    DETAIL_CONTACT_SUCCESS,
    DETAIL_CONTACT_FAIL,
    DELETE_CONTACT_REQUEST,
    DELETE_CONTACT_SUCCESS,
    DELETE_CONTACT_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
    RESET_DETAIL
} from '../constants/contactConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get contact::begin
export const getContact = () => async (dispatch) => {
    try {
        dispatch({ type: GET_CONTACT_REQUEST });

        const config = {
            headers: {
                "Content-Type": "CONTACT/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/contact/getContact`, config);

        dispatch({ type: GET_CONTACT_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_CONTACT_FAIL, payload: error.response.data.message });
    }
}
// get contact::end

// create contact::begin
export const createContact = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_CONTACT_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.post(`${globalLink}/api/contact/createContact`, data, config);

        dispatch({ type: CREATE_CONTACT_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_CONTACT_FAIL, payload: error.response.data.message });
    }
}
// create contact::end

// detail contact::begin
export const detailContact = (id) => async (dispatch) => {
    try {
        dispatch({ type: DETAIL_CONTACT_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/contact/detailContact/${id}`, config);

        dispatch({ type: DETAIL_CONTACT_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DETAIL_CONTACT_FAIL, payload: error.response.data.message });
    }
}
// detail contact::end

// delete contact::begin
export const deleteContact = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_CONTACT_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.delete(`${globalLink}/api/contact/deleteContact/${id}`, config);

        dispatch({ type: DELETE_CONTACT_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_CONTACT_FAIL, payload: error.response.data.message });
    }
}
// delete contact::end

// resets::begin
export const resetNew = () => async (dispatch) => {
    dispatch({ type: RESET_NEW });
};

export const resetDelete = () => async (dispatch) => {
    dispatch({ type: RESET_DELETE });
};

export const resetDetail = () => async (dispatch) => {
    dispatch({ type: RESET_DETAIL });
};
// resets::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end