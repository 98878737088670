import React from 'react';
import profile from '../../assets/images/profile/profile.png';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
  const navigate = useNavigate();

  const sendToAbout = () => {
    return navigate("/about");
  }

  return (
    <>
      <section className="banner-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12 col-sm-12 col-lg-12">
              <div className="banner-wrapper">
                <div className="banner-parent-wrapper">
                  <div className="banner-childs">
                    <h4>Hello, I'm</h4>
                    <div className="bhim-bahadur-gurung-intro">
                      <h2>Bhim Gurung</h2>
                      <p>A <span className="designation">professional Co-operator </span> from <span className="country">Nepal</span></p>
                    </div>
                    <p>
                      I’ve been serving as the CEO of Pokhara Royal Co-operative for the last two decades.
                    </p>
                    <div className="banner-more">
                      <div className="banner-more-parent">
                        <div className="banner-more-childs">
                          <div className="banner-btn">
                            <button onClick={sendToAbout}>About Me</button>
                          </div>
                        </div>
                        <div className="banner-more-childs">
                          <div className="banner-social-media">
                            <a href="https://www.facebook.com/bhim.gurung.549668" target="_blank" rel="noreferrer">
                              <div className="banner-social-media-wrapper">
                                <i className="fab fa-facebook-f"></i>
                              </div>
                            </a>
                            <a href="https://www.instagram.com/bhimgurung777/" target="_blank" rel="noreferrer">
                              <div className="banner-social-media-wrapper">
                                <i className="fab fa-instagram"></i>
                              </div>
                            </a>
                            <a href="https://www.gmail.com/">
                              <div className="banner-social-media-wrapper" target="_blank" rel="noreferrer">
                                <i className="fa fa-envelope"></i>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="banner-childs">
                    <img src={profile} draggable={false} alt="bhim_bahadur_gurung" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner