import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Notification from '../../components/notification/Notification';
import Loader from '../../components/loader/Loader';
import { login, clearErrors } from '../../states/actions/userAction';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isAuthenticated, loading, error: loginError } = useSelector((state) => state.users);

    const [userDetail, setUserDetail] = useState({
        email: "",
        password: "",
    });

    const [isError, setIsError] = useState({
        isThereError: false,
        errorMessage: "",
        errorType: "",
    });

    const onchangeHander = (e) => {
        setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
    }

    const sumbitHandler = (e) => {
        e.preventDefault();
        if (!userDetail.email) {
            return setIsError({
                isThereError: true,
                errorMessage: "Please provide email address!",
                errorType: "danger",
            });
        }

        if (!userDetail.password) {
            return setIsError({
                isThereError: true,
                errorMessage: "Please enter password!",
                errorType: "danger",
            });
        }

        if (userDetail.email.includes("@gmail.com") === false) {
            return setIsError({
                isThereError: true,
                errorMessage: "Email address is invalid, try again",
                errorType: "danger",
            });
        }

        dispatch(login(userDetail.email, userDetail.password));
    }

    const closeError = () => {
        return setIsError({
            isThereError: false,
            errorMessage: "",
            errorType: "",
        });
    }

    const sendToHome = () => {
        return navigate("/");
    }

    useEffect(() => {
        if (loginError) {
            setIsError({
                isThereError: true,
                errorMessage: loginError,
                errorType: "danger",
            });
            dispatch(clearErrors());
        }

        if (isAuthenticated) {
            return navigate("/dashboard");
        }
    }, [dispatch, loginError, isAuthenticated, navigate]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="auth-section">
                <div className="auth-wrapper">
                    <div className="auth-inner-wrapper">
                        {
                            isError.isThereError
                                ?
                                <Notification message={isError.errorMessage} type={isError.errorType} closeError={closeError} />
                                :
                                <></>

                        }
                        <h3>LOGIN</h3>
                        <p>Hy, Please Login</p>
                        <form className="global-form">
                            <div className="input-group">
                                <label>Enter Email Address</label>
                                <input
                                    type="text"
                                    placeholder="example@gmail.com"
                                    name="email"
                                    value={userDetail.email}
                                    onChange={(e) => onchangeHander(e)}
                                />
                            </div>
                            <div className="input-group">
                                <label>Enter Password</label>
                                <input
                                    type="password"
                                    placeholder="Please enter password"
                                    name="password"
                                    value={userDetail.password}
                                    onChange={(e) => onchangeHander(e)}
                                />
                            </div>
                            <div className="global-btn-100">
                                <button onClick={sumbitHandler}>LOGIN</button>
                            </div>
                            <div className="global-btn-100 mt-2">
                                <button onClick={sendToHome}><i className="fa fa-arrow-left"></i> Back to site</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login;