import {
    FETCH_UPLOAD_REQUEST,
    FETCH_UPLOAD_SUCCESS,
    FETCH_UPLOAD_FAIL,
    CREATE_UPLOAD_REQUEST,
    CREATE_UPLOAD_SUCCESS,
    CREATE_UPLOAD_FAIL,
    DELETE_UPLOAD_REQUEST,
    DELETE_UPLOAD_SUCCESS,
    DELETE_UPLOAD_FAIL,
    CLEAR_ERRORS,
    RESET_DETAIL,
    RESET_NEW,
    RESET_DELETE,
    RESET_UPDATE
} from "../constants/uploadConstant";
import axios from "axios";

const globalLink = process.env.REACT_APP_API_URL;

// get upload::begin
export const getUpload = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_UPLOAD_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/upload/getUpload`, config);

        dispatch({ type: FETCH_UPLOAD_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: FETCH_UPLOAD_FAIL, payload: error.response.data.message });
    }
}
// get upload::end

// create upload::begin
export const createUpload = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_UPLOAD_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        const res = await axios.post(`${globalLink}/api/upload/createUpload`, data, config);

        dispatch({ type: CREATE_UPLOAD_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_UPLOAD_FAIL, payload: error.response.data.message });
    }
};
// create upload::end

// delete upload::begin
export const deleteUpload = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_UPLOAD_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.delete(`${globalLink}/api/upload/deleteUpload/${id}`, config);

        dispatch({ type: DELETE_UPLOAD_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_UPLOAD_FAIL, payload: error.response.data.message });
    }
};
// delete upload::end

// reset::begin
export const resetDetail = () => async (dispatch) => {
    dispatch({ type: RESET_DETAIL });
};

export const resetNew = () => async (dispatch) => {
    dispatch({ type: RESET_NEW });
};

export const resetUpdate = () => async (dispatch) => {
    dispatch({ type: RESET_UPDATE });
};

export const resetDelete = () => async (dispatch) => {
    dispatch({ type: RESET_DELETE });
};
// reset::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end