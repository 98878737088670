import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Notify from '../notify/Notify';
import JoditEditor from 'jodit-react';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import Loader from '../../../components/loader/Loader';
import img_pre from '../../../assets/images/designs/img-pre.png';
import { updateBlog, detailBlog, resetDetail, resetUpdate, clearErrors } from '../../../states/actions/blogAction';

const UpdateBlog = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { loading, error, blog, } = useSelector((state) => state.blogDetail);
    const { loading: updateLoading, error: updateError, isUpdated } = useSelector((state) => state.blog);

    const editor = useRef(null);
    const [content, setContent] = useState("");
    const [file, setFile] = useState(null);
    const [oldFile, setOldFile] = useState(null);

    const [blogVal, setBlogVal] = useState({
        title: "",
        orientation: "",
        visibility: "",
        category: ""
    });

    const onChangeHandler = (e) => {
        setBlogVal({ ...blogVal, [e.target.name]: e.target.value });
    }

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const submitHandller = (e) => {
        e.preventDefault();

        if (!blogVal.title) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Title is mandatory!"
            });
        };

        if (!content) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Description is mandatory!"
            });
        };

        if (!blogVal.visibility) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Visibility is mandatory!"
            });
        };

        if (!blogVal.category) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Category is mandatory!"
            });
        };

        if (file !== null) {
            if ((file.size / 1024) >= 500) {
                return setIsMessage({
                    isThereMessage: true,
                    messageType: "danger",
                    message: "File size should be less than '500kb'!"
                });
            };
        }

        const myForm = new FormData();
        myForm.append("title", blogVal.title);
        myForm.append("orientation", blogVal.orientation);
        myForm.append("description", content);
        myForm.append("visibility", blogVal.visibility);
        myForm.append("category", blogVal.category);

        if (file !== null) {
            myForm.append("thumbnail", file);
        }

        return dispatch(updateBlog(myForm, id));
    }

    useEffect(() => {
        dispatch(detailBlog(id));
        setBlogVal({
            title: blog.title,
            orientation: blog.orientation,
            visibility: blog.visibility,
            category: blog.category
        });
        setContent(blog.description);
        setOldFile(blog.image);

        if (isUpdated) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: isUpdated.message
            });
            navigate("/dashboard/manageBlog");
            dispatch(resetDetail());
            dispatch(resetUpdate());
        }

        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            dispatch(clearErrors());
        }

        if (updateError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: updateError
            });
            dispatch(clearErrors());
        }
    }, [
        dispatch, 
        error, 
        isUpdated, 
        navigate, 
        updateError, 
        id, 
        blog.orientation, 
        blog.title, 
        blog.description, 
        blog.image,
        blog.category,
        blog.visibility,
    ]);

    return (
        <>
            {loading || updateLoading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Update Blog" />
            <div className="dashboard-forms">
                <h4>UPDATE BLOG</h4>
                {
                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                }
                <form>
                    <div className="input-group">
                        <label className="dashboard-label">Title</label>
                        <input
                            type="text"
                            className="dashboard-input"
                            placeholder="Enter blog title"
                            name="title"
                            value={blogVal.title}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Orientation</label>
                        <input
                            type="number"
                            className="dashboard-input"
                            placeholder="Enter blog orientation"
                            name="orientation"
                            value={blogVal.orientation}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Description</label>
                        <JoditEditor
                            ref={editor}
                            value={content}
                            tabIndex={1}
                            onChange={newContent => setContent(newContent)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Visibility <span>(Alert! - This will use to hide and show the blog to viewer!)</span></label>
                        <select
                            className="dashboard-input"
                            name="visibility"
                            value={blogVal.visibility}
                            onChange={(e) => onChangeHandler(e)}
                        >
                            <option>CHOOSE VISIBILITY</option>
                            <option value="visible">Visible</option>
                            <option value="hidden">Hidden</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Category</label>
                        <select
                            className="dashboard-input"
                            name="category"
                            value={blogVal.category}
                            onChange={(e) => onChangeHandler(e)}
                        >
                            <option>CHOOSE CATEGORY</option>
                            <option value="Cooperative">Cooperative</option>
                            <option value="Literature">Literature</option>
                            <option value="Training Tips">Training Tips</option>
                            <option value="Motivation">Motivation</option>
                            <option value="Interview">Interview</option>
                            <option value="Social/Economic/Political">Social/Economic/Political</option>
                            <option value="Personal">Personal</option>
                        </select>
                    </div>
                    <div className="input-group-type-file">
                        <label className="dashboard-label">Thumbnail <span>(Alert! - File size should be less then "500kb")</span></label>
                        <input
                            type="file"
                            className="dashboard-input-type-file"
                            accept="image/*"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </div>
                    {
                        file && file
                            ?
                            <div className="form-img-preview">
                                <img src={URL.createObjectURL(file)} draggable={false} alt="preview" />
                                <div className="file-remove-btn">
                                    <button onClick={() => setFile(null)}>REMOVE <i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            :
                            <div className="form-img-preview">
                                <img src={img_pre} draggable={false} alt="preview" />
                            </div>
                    }
                    {
                        oldFile && oldFile
                            ?
                            <div className="form-img-preview">
                                <img src={oldFile} draggable={false} alt="preview" />
                            </div>
                            :
                            <></>
                    }
                    <div className="dashboard-submit-btn100">
                        <button onClick={submitHandller}>UPDATE</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default UpdateBlog;