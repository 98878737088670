import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOAD_REQUEST,
    LOAD_SUCCESS,
    LOAD_FAIL,
    LOGOUT_FAIL,
    PASSWORD_REQUEST,
    PASSWORD_SUCCESS,
    PASSWORD_FAIL,
    CLEAR_ERRORS,
    RESET_PASSWORD
} from '../constants/userConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// loaduser::begin
export const loaduser = () => async (dispatch) => {

    const config = {
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
    };

    try {
        dispatch({ type: LOAD_REQUEST });

        const res = await axios.get(`${globalLink}/api/user/loaduser`, config, axios.defaults.withCredentials = true);

        dispatch({ type: LOAD_SUCCESS, payload: res });
    } catch (error) {
        // dispatch({ type: LOAD_FAIL, payload: error.response.data.message });
        dispatch({ type: LOAD_FAIL });
    }
}
// loaduser::end

// login::begin
export const login = (email, password) => async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });

    const config = {
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
        },
    };

    try {
        const res = await axios.post(`${globalLink}/api/user/login`, { email, password }, config, axios.defaults.withCredentials = true);
        dispatch({ type: LOGIN_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
    }
}
// login::end

// logout::begin
export const userLogout = () => async (dispatch) => {
    try {

        const config = {
            credentials: 'include',
            headers: { "Content-Type": "application/json" },
        };

        const res = await axios.post(`${globalLink}/api/user/logout`, config, axios.defaults.withCredentials = true);

        dispatch({ type: LOGOUT_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: LOGOUT_FAIL, payload: error.response.data.message });
    }
}
// logout::end

// change password::begin
export const changePassword = (data) => async (dispatch) => {
    const config = {
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
    };
    dispatch({ type: PASSWORD_REQUEST });
    try {
        const res = await axios.post(`${globalLink}/api/user/chnagePassword`, data, config, axios.defaults.withCredentials = true);
        dispatch({ type: PASSWORD_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: PASSWORD_FAIL, payload: error.response.data.message });
    }
}
// change password::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};

export const resetPassword = () => async (dispatch) => {
    dispatch({ type: RESET_PASSWORD });
};
// clearError::end