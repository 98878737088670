import React, { useEffect } from 'react';
import Navigation from './components/layout/Navigation';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Home from './pages/views/Home';
import Footer from './components/layout/Footer';
import AboutPage from './pages/views/subpages/AboutPage';
import BlogPage from './pages/views/subpages/BlogPage';
import ContactPage from './pages/views/subpages/ContactPage';
import Login from './pages/auth/Login';
import { loaduser } from './states/actions/userAction';
import { traficManager } from './states/actions/webInfoAction';
import BlogDetail from './pages/views/subpages/BlogDetail';
import Success from './pages/views/subpages/Success';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(traficManager());
    dispatch(loaduser());
  }, [dispatch]);
  
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<AboutPage />} exact />
        <Route path="/blog" element={<BlogPage />} exact />
        <Route path="/contact" element={<ContactPage />} exact />
        <Route path="/login" element={<Login />} exact />
        <Route path="/blogDetail/:id" element={<BlogDetail />} exact />
        <Route path="/success" element={<Success />} exact />
      </Routes>
      <Footer />
    </>
  )
}

export default App;