import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ title }) => {
    return (
        <>
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3><Link to="/">Home</Link> <i className="fa fa-arrow-right"></i> {title}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Breadcrumb