import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { passwordReducer, userReducer } from "./states/reducers/userReducer";
import { blogDetailReducer, blogReducer, blogsReducer, newBlogReducer } from "./states/reducers/blogReducer";
import { newUploadReducer, uploadReducer, uploadsReducer } from "./states/reducers/uploadReducer";
import { contactDetailReducer, contactReducer, contactsReducer, newContactReducer } from "./states/reducers/contactReducer";
import { newTraficReducer, webInfoReducer } from "./states/reducers/webInfoReducer";
import { frontendReducer } from "./states/reducers/frontendReducer";

const reducer = combineReducers({
    traficManager: newTraficReducer,
    frontend: frontendReducer,
    webInfo: webInfoReducer,
    users: userReducer,
    blogs: blogsReducer,
    newBlog: newBlogReducer,
    blog: blogReducer,
    blogDetail: blogDetailReducer,
    uploads: uploadsReducer,
    newUpload: newUploadReducer,
    upload: uploadReducer,
    contacts: contactsReducer,
    contact: contactReducer,
    contactDetail: contactDetailReducer,
    newContact: newContactReducer,
    security: passwordReducer,
});

const middleware = [thunk];

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;