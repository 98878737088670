export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const GET_CONTACT_FAIL = "GET_CONTACT_FAIL";

export const CREATE_CONTACT_REQUEST = "CREATE_CONTACT_REQUEST";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAIL = "CREATE_CONTACT_FAIL";

export const DETAIL_CONTACT_REQUEST = "DETAIL_CONTACT_REQUEST";
export const DETAIL_CONTACT_SUCCESS = "DETAIL_CONTACT_SUCCESS";
export const DETAIL_CONTACT_FAIL = "DETAIL_CONTACT_FAIL";

export const DELETE_CONTACT_REQUEST = "DELETE_CONTACT_REQUEST";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL";

export const RESET_NEW = "RESET_NEW";
export const RESET_DELETE = "RESET_DELETE";
export const RESET_DETAIL = "RESET_DETAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";