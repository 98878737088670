import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import AdminSideBar from './AdminSideBar';
import DashboardLanding from './DashboardLanding';
import '../../scss/dashboard.scss';
import BlogIndex from './blog/BlogIndex';
import BlogView from './blog/BlogView';
import UpdateBlog from './blog/UpdateBlog';
import UploadIndex from './upload/UploadIndex';
import ContactIndex from './contact/ContactIndex';
import ContactView from './contact/ContactView';
import System from './system/System';
import SecurityIndex from './security/SecurityIndex';

const Dashboard = () => {
    return (
        <>
            
            <section className="dashboard-section">
                <div className="dashboard-parent-wrapper">
                    <div className="dashboard-childs-wrapper">
                        <AdminSideBar />
                    </div>
                    <div className="dashboard-childs-wrapper">
                        <div className="dashboard-header-wrapper">
                            <div className="dashboard-header-childs">
                                <h3>EDashboard - <span>Welcome Admin</span></h3>
                            </div>
                            <div className="dashboard-header-childs">
                                <ul>
                                    <li><Link to="/dashboard/"><i className="fa fa-chart-line"></i>Report</Link></li>
                                    <li><Link to="/dashboard/manageContact"><i className="fa fa-bell"></i>Messages</Link></li>
                                    <li><Link to="/dashboard/manageBlog"><i className="fa fa-book"></i>Blog</Link></li>
                                    <li><Link to="/dashboard/system"><i className="fa fa-cog"></i>System</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="dashboard-content-wrapper">
                            <Routes>
                                <Route path="/" element={<DashboardLanding />} />

                                <Route path="/manageBlog" element={<BlogIndex />} />
                                <Route path="/blogView/:id" element={<BlogView />} />
                                <Route path="/blogUpdate/:id" element={<UpdateBlog />} />

                                <Route path="/manageUpload" element={<UploadIndex />} />

                                <Route path="/manageContact" element={<ContactIndex />} />
                                <Route path="/contactView/:id" element={<ContactView />} />

                                <Route path="/system" element={<System />} />
                                <Route path="/security" element={<SecurityIndex />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard;