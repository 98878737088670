import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Notify from '../../pages/dashboard/notify/Notify';
import Loader from '../../components/loader/Loader';
import deco1 from '../../assets/images/designs/purplebg.png';
import { clearErrors, createContact, resetNew } from '../../states/actions/contactAction';

const Contact = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, success, error } = useSelector((state) => state.newContact);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [contactVal, setContactVal] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    });

    const onChangeHandler = (e) => {
        setContactVal({ ...contactVal, [e.target.name]: e.target.value });
    }

    const submitHandler = (e) => {
        e.preventDefault(e);

        if (!contactVal.name) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please provide your name!"
            });
        }

        if (!contactVal.email) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please provide valid email address!"
            });
        }

        if (!contactVal.phone) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please provide phone!"
            });
        }

        if (!contactVal.message) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please write some message!"
            });
        }

        if (contactVal.phone.length !== 10) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Phone number shound include 10 digits!"
            });
        };

        if (contactVal.email.includes("@gmail.com") !== true) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Email is invalid!"
            });
        };

        const myForm = new FormData()
        myForm.append("name", contactVal.name);
        myForm.append("email", contactVal.email);
        myForm.append("phone", contactVal.phone);
        myForm.append("message", contactVal.message);

        dispatch(createContact(myForm));
    }

    useEffect(() => {
        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            dispatch(clearErrors);
        }
        if (success) {
            navigate("/success");
            dispatch(resetNew());
        };
    }, [dispatch, success, error, navigate]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="contact-section">
                <img src={deco1} draggable={false} alt="deco" className="contact-demo-1" />
                <img src={deco1} draggable={false} alt="deco" className="contact-demo-2" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="global-heading">
                                <h3>CONTACT</h3>
                                <p>Contact if you have any queries !</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            {/* <iframe title="omething" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcodesastra.np%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=697618428209516" width="340" height="500" style={{border:"none", overFlow:"hidden"}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
                            <div className="row">
                                <div className="col-md-12 mt-3">
                                    <div className="contact-info-wrapper">
                                        <i className="fa fa-phone"></i>
                                        <h3>9856081510</h3>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="contact-info-wrapper">
                                        <i className="fa fa-envelope"></i>
                                        <h3>bhimgrg2030@gmail.com  </h3>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="contact-info-wrapper">
                                        <i className="fa fa-map-marker-alt"></i>
                                        <h3>Pokhara-10,Simalmarga, Kaski</h3>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="contact-info-wrapper">
                                        <i className="fa fa-globe"></i>
                                        <h3>bhimgrg.com</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="callback-form">
                                {
                                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                                }
                                <div className="drop-a-message">
                                    <h3>Contact Form</h3>
                                    <p>I will get back to you with in 24 hours</p>
                                </div>
                                <form className="global-form">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <label className="label">Enter Name</label><span>*</span>
                                                    <input
                                                        type="text"
                                                        placeholder="Your Name"
                                                        name="name"
                                                        value={contactVal.name}
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <label className="label">Email Address</label><span>*</span>
                                                    <input
                                                        type="email"
                                                        placeholder="Ex - example@gmail.com"
                                                        name="email"
                                                        value={contactVal.email}
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <label className="label">Mobile No.</label><span>*</span>
                                                    <input
                                                        type="number"
                                                        placeholder="+977 - Mobile no."
                                                        name="phone"
                                                        value={contactVal.phone}
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <label className="label">Anything to say</label><span>*</span>
                                                    <textarea
                                                        rows="3"
                                                        placeholder="Message / Feedback"
                                                        name="message"
                                                        value={contactVal.message}
                                                        onChange={(e) => onChangeHandler(e)}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="global-btn">
                                                    <button onClick={submitHandler}>SEND <i className="fa fa-paper-plane"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;