import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import CreateBlog from './CreateBlog';
import Notify from '../notify/Notify';
import Loader from '../../../components/loader/Loader';
import Conformation from '../conformation/Conformation';
import NotFound from '../notfound/NotFound';
import moment from 'moment';
import { clearErrors, deleteBlog, getBlog, resetDelete } from '../../../states/actions/blogAction';

const BlogIndex = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, blogs, error } = useSelector(state => state.blogs);
    const { loading: deleteLoading, error: deleteError, isDeleted, message: deleteMessage } = useSelector(state => state.blog);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const [confirmBox, setConfirmBox] = useState(false);
    const [cid, setCid] = useState(null);

    const proceedToConfirm = (id) => {
        setCid(id);
        setConfirmBox(true);
    }

    const proceedToDelete = () => {
        setConfirmBox(false);
        return dispatch(deleteBlog(cid));
    }

    const sendToView = (id) => {
        return navigate(`/dashboard/blogView/${id}`);
    }

    const sendToUpdate = (id) => {
        return navigate(`/dashboard/blogUpdate/${id}`);
    }

    useEffect(() => {
        if (deleteError) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: deleteError
            });
            dispatch(clearErrors());
        }

        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            dispatch(clearErrors());
        }

        if (isDeleted) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: deleteMessage
            });
            dispatch(resetDelete());
        }

        dispatch(getBlog());
    }, [dispatch, error, isDeleted, deleteError, deleteMessage]);
    return (
        <>
            {loading || deleteLoading ? <Loader /> : <></>}
            {
                confirmBox
                    ?
                    <Conformation setConfirmBox={setConfirmBox} proceedFurther={proceedToDelete} message="Hy...Do you want to delete this blog?" btnName="DELETE" />
                    :
                    <></>
            }
            <DashboardBreadcrumb title="Manage Blogs" />
            <section className="dashboard-operation-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="dashboard-data-table">
                                {
                                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                                }
                                {
                                    blogs && blogs.length <= 0
                                        ?
                                        <NotFound message="Blog not found!" />
                                        :

                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Prev</th>
                                                    <th>Date</th>
                                                    <th>Orientation</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {

                                                    blogs && blogs.map((data, index) => (
                                                        <tr key={index}>
                                                            <td className="table-image">
                                                                <img src={data.image} draggable={false} alt="table" />
                                                            </td>
                                                            <td>{moment(data.created_at).format('MMM Do YYYY')}</td>
                                                            <td>{data.orientation} - {data.visibility}</td>
                                                            <td className="table-action">
                                                                <button className="view" onClick={() => sendToView(data.id)}>VIEW</button>
                                                                <button className="update" onClick={() => sendToUpdate(data.id)}>UPDATE</button>
                                                                <button className="delete" onClick={() => proceedToConfirm(data.id)}>DELETE</button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <CreateBlog />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default BlogIndex;