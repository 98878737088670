import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Conformation from './conformation/Conformation';
import { userLogout } from '../../states/actions/userAction';

const AdminSideBar = () => {
    const dispatch = useDispatch();
    const [confirmBox, setConfirmBox] = useState(false);

    const proceedToConfirm = () => {
        return setConfirmBox(true);
    }

    const proceedToLogout = () => {
        return dispatch(userLogout());
    }
    return (
        <>
            {
                confirmBox
                    ?
                    <Conformation setConfirmBox={setConfirmBox} proceedFurther={proceedToLogout} message="Hy, Do you want to logout?" btnName="LOGOUT" />
                    :
                    <></>
            }
            <div className="side-navigation">
                <ul>
                    <h3>MAIN</h3>
                    <li><Link to="/dashboard"><i className="fa fa-list-alt"></i>Dashboard</Link></li>
                    <li><Link to="/dashboard/security"><i className="fa fa-desktop"></i>Security</Link></li>
                </ul>
                <ul>
                    <h3>LISTS</h3>
                    <li><Link to="/dashboard/manageBlog"><i className="fa fa-book"></i>Manage Blogs</Link></li>
                </ul>
                <ul>
                    <h3>NOTICES</h3>
                    <li><Link to="/dashboard/manageContact"><i className="fa fa-comment"></i>Messages</Link></li>
                </ul>
                <ul>
                    <h3>GALLERY</h3>
                    <li><Link to="/dashboard/manageUpload"><i className="fa fa-upload"></i>Uploads</Link></li>
                </ul>
                <ul>
                    <h3>ADMIN</h3>
                    <li><button onClick={proceedToConfirm} className="logoutBtn pointer text-danger"><i className="fa fa-sign-out-alt text-danger"></i> Logout</button></li>
                </ul>
            </div>
        </>
    )
}

export default AdminSideBar;