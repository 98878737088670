import React from 'react';
import about_image from '../../assets/images/web_img/bhum_grg_about_img.JPG';
import deco1 from '../../assets/images/designs/purplebg.png';
import { useNavigate } from 'react-router-dom';

const About = ({ data }) => {
    const navigate = useNavigate();

    const sendToAbout = () => {
        return navigate("/about");
    }
    return (
        <>
            <section className={`about-section ${data}`}>
                <div className="about-deco">
                    <img src={deco1} draggable={false} alt="deco" />
                    <img src={deco1} draggable={false} alt="deco" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="global-heading">
                                <h3>ABOUT ME</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="bhim-bahadur-gurung-about-image">
                                <img src={about_image} alt="bhim_bahadur_gurung" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-sub-headings">
                                {/* <p>I am a professional Co-operator</p> */}
                                <h4>I am a professional Co-operator</h4>
                            </div>
                            <div className="about-info-wrapper">
                                <p className="explan-txt">
                                    I am Bhim Gurung, a professional Co-operator from Nepal. I’ve been serving as the CEO of Pokhara Royal Co-operative for the last two decades. As a certified motivational trainer from ACCU, Thailand, I facilitate leadership and Cooperative management training. I also take interest in literature, musical arts, sports, and social service.
                                </p>
                                {
                                    data
                                        ?
                                        <></>
                                        :
                                        <div className="global-btn mt-4">
                                            <button onClick={sendToAbout}>Read More <i className="fa fa-arrow-right"></i></button>
                                        </div>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About