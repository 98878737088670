import {
    CREATE_BLOG_REQUEST,
    CREATE_BLOG_SUCCESS,
    CREATE_BLOG_FAIL,
    GET_BLOG_REQUEST,
    GET_BLOG_SUCCESS,
    GET_BLOG_FAIL,
    DETAIL_BLOG_REQUEST,
    DETAIL_BLOG_SUCCESS,
    DETAIL_BLOG_FAIL,
    DELETE_BLOG_REQUEST,
    DELETE_BLOG_SUCCESS,
    DELETE_BLOG_FAIL,
    UPDATE_BLOG_REQUEST,
    UPDATE_BLOG_SUCCESS,
    UPDATE_BLOG_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
    RESET_DETAIL,
    RESET_UPDATE,
} from '../constants/blogConstant';

export const blogsReducer = (state = { blogs: [] }, action) => {
    switch (action.type) {
        case GET_BLOG_REQUEST:
            return {
                loading: true,
                blogs: []
            };

        case GET_BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                blogs: action.payload.data.data
            };

        case GET_BLOG_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const newBlogReducer = (state = { blog: {} }, action) => {
    switch (action.type) {
        case CREATE_BLOG_REQUEST:
            return {
                loading: true,
            };

        case CREATE_BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_BLOG_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RESET_NEW:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const blogDetailReducer = (state = { blog: {} }, action) => {
    switch (action.type) {
        case DETAIL_BLOG_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_BLOG_SUCCESS:
            return {
                loading: false,
                blog: action.payload.data.data
            };

        case DETAIL_BLOG_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case RESET_DETAIL:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const blogReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_BLOG_REQUEST:
        case UPDATE_BLOG_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data.success,
                message: action.payload.data.message
            };

        case UPDATE_BLOG_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload.data,
            };

        case DELETE_BLOG_FAIL:
            case UPDATE_BLOG_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_UPDATE:
            return {
                ...state,
                isUpdated: false,
            };

        case RESET_DELETE:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};


