import React from 'react'
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import About from '../About';

const AboutPage = () => {

  const location = useLocation();

  return (
    <>
        <Breadcrumb title="About" />
        <About data={location.pathname === "/about" ? "noTopMargin" : ""}/>
    </>
  )
}

export default AboutPage;