import {
    GET_WEBINFO_REQUEST,
    GET_WEBINFO_SUCCESS,
    GET_WEBINFO_FAIL,
    CREATE_TRAFIC_FAIL,
    CREATE_TRAFIC_SUCCESS,
    CREATE_TRAFIC_REQUEST
} from '../constants/webInfoConstant';

import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get webInfo::begin
export const getWebInfo = () => async (dispatch) => {
    try {
        dispatch({ type: GET_WEBINFO_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/webInfo/getWebInfo`, config);

        dispatch({ type: GET_WEBINFO_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_WEBINFO_FAIL, payload: error.response.data.message });
    }
}
// get webInfo::end

// trafic manager::begin
export const traficManager = () => async (dispatch) => {
    try {
        dispatch({ type: CREATE_TRAFIC_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.post(`${globalLink}/api/webInfo/traficManager`, config);

        dispatch({ type: CREATE_TRAFIC_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_TRAFIC_FAIL, payload: error.response.data.message });
    }
}
// trafic manager::end