import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb';
import Loader from '../../../components/loader/Loader';
import { detailBlog, resetDetail } from '../../../states/actions/blogAction';

const BlogDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { loading, blog } = useSelector(state => state.blogDetail);

    useEffect(() => {
        dispatch(detailBlog(id));

        return () => {
            dispatch(resetDetail);
        }
    }, [dispatch, id]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            <Breadcrumb title="Blog Detail" />
            <section className="blog-detail-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blog-detail-page">
                                <div dangerouslySetInnerHTML={{ __html: blog && blog.description }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogDetail;