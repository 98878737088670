import {
    FETCH_UPLOAD_REQUEST,
    FETCH_UPLOAD_SUCCESS,
    FETCH_UPLOAD_FAIL,
    CREATE_UPLOAD_REQUEST,
    CREATE_UPLOAD_SUCCESS,
    CREATE_UPLOAD_FAIL,
    DELETE_UPLOAD_REQUEST,
    DELETE_UPLOAD_SUCCESS,
    DELETE_UPLOAD_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
} from "../constants/uploadConstant";

export const uploadsReducer = (state = { uploads: [] }, action) => {
    switch (action.type) {
        case FETCH_UPLOAD_REQUEST:
            return {
                loading: true,
                uploads: []
            };

        case FETCH_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                uploads: action.payload.data.data
            };

        case FETCH_UPLOAD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const newUploadReducer = (state = { upload: {} }, action) => {
    switch (action.type) {
        case CREATE_UPLOAD_REQUEST:
            return {
                loading: true,
                upload: {}
            };

        case CREATE_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                upload: action.payload.data
            };

        case CREATE_UPLOAD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case RESET_NEW:
            return {
                ...state,
                success: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const uploadReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data,
            };

        case DELETE_UPLOAD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_DELETE:
            return {
                ...state,
                isDeleted: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

