export const data = {
    version: "v1.0.0",
    last_update_date: "1/3/2023",
    security: "Token based security",
    security_status: "Good",
    system_uses: "medium",
    external_threat: "low",
    registered_admin: "Bhim Bahadur Gurung",
    registered_admin_count: "1",
    monitor: "enable",
    system_state: "Production"
}