import React from 'react';
import { Line } from 'react-chartjs-2';
import './my_chart.css';

const WebTrafic = ({ count }) => {
    const lineState = {
        labels: ["Initial Web Trafic", "Trafic Till Now"],
        datasets: [
            {
                label: "TOTAL TRAFIC",
                backgroundColor: ["purple"],
                hoverBackgroundColor: ["rgb(197, 72, 49)"],
                data: [0, count],
            },
        ],
    };
    return (
        <>
            <div className="my-chart-100 ">
                <Line data={lineState} />
            </div>
        </>
    )
}

export default WebTrafic;