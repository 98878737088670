import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../src/components/loader/Loader';
import WebTrafic from './charts/WebTrafic';
import Chart from "chart.js/auto";
import adminImg from '../../assets/images/admin.png'
import { getWebInfo } from '../../states/actions/webInfoAction';

const DashboardLanding = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, blog, upload, message, trafic } = useSelector(state => state.webInfo.webInfo);

    const sendToSecurity = () => {
        return navigate("/dashboard/security");
    }

    useEffect(() => {
        dispatch(getWebInfo());
    }, [dispatch]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>WEB TRAFIC</p>
                                <h3>{ trafic && trafic.count }</h3>
                                <Link to="/dashboard">Analyzing...</Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-globe"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>BLOGS</p>
                                <h3>{blog && blog.countBlog}</h3>
                                <Link to="/dashboard/manageBlog">View All <i className="fa fa-arrow-right"></i></Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-book"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>MESSAGES</p>
                                <h3>{message && message.countMessage}</h3>
                                <Link to="/dashboard/manageContact">View All <i className="fa fa-arrow-right"></i></Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-comment-alt"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="report-card-parent">
                            <div className="report-card-childs">
                                <p>UPLOADS</p>
                                <h3>{upload && upload.countUpload}</h3>
                                <Link to="/dashboard/manageUpload">View All <i className="fa fa-arrow-right"></i></Link>
                            </div>
                            <div className="report-card-childs">
                                <i className="fa fa-file"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-md-7">
                        <div className="dashboard-box">
                            <WebTrafic count={trafic && trafic.count} />
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="dashboard-box bg-gray">
                            <div className="admin-profile-wrapper">
                                <div className="admin-profile">
                                    <img src={adminImg} alt="admin" />
                                </div>
                                <div className="admin-info">
                                    <h3>Bhim Bahadur Gurung</h3>
                                    <p>Authorized Admin</p>
                                    <button onClick={sendToSecurity}>Change Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardLanding;