import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import DashboardBreadcrumb from '../dashboardBreadcrumb/DashboardBreadcrumb';
import Notify from '../notify/Notify';
import Loader from '../../../components/loader/Loader';
import { clearErrors, detailBlog } from '../../../states/actions/blogAction';

const BlogView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { loading, error, blog } = useSelector(state => state.blogDetail);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    useEffect(() => {
        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            return clearErrors();
        }

        dispatch(detailBlog(id));
    }, [dispatch, id, error]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <DashboardBreadcrumb title="Blog Detail" />
            <div className="dashboard-detail-page">
                {
                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                }
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="dashboard-detail-page-wrapper">
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>blog_id -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{blog && blog.id}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Blog Title -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{blog && blog.title}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Orientation -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{blog && blog.orientation}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Visibility -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{blog && blog.visibility}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Description -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{blog && blog.category}</p>
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Description -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <div dangerouslySetInnerHTML={{ __html: blog && blog.description }} />
                                    </div>
                                </div>
                                <div className="dashboard-detail-page-parent">
                                    <div className="dashboard-detail-page-childs">
                                        <p>Date -</p>
                                    </div>
                                    <div className="dashboard-detail-page-childs">
                                        <p>{moment(blog && blog.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogView;