import React from 'react';
import { Link } from 'react-router-dom';

const Success = () => {
  
  return (
    <>
      <div className="success-message-wrapper">
        <div className="success-inner">
          <h6><i className="fa fa-check-circle"></i></h6>
          <h3>Message Sent Successfully</h3>
          <p>Thank You!</p>
          <p className="mb-4">I will get back to you soon!</p>
          <Link to="/">Back To Site <i className="fa fa-angle-right"></i></Link>
        </div>
      </div>
    </>
  )
}

export default Success