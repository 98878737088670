import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notify from '../notify/Notify';
import JoditEditor from 'jodit-react';
import Loader from '../../../components/loader/Loader';
import img_pre from '../../../assets/images/designs/img-pre.png';
import { createBlog, getBlog, resetNew } from '../../../states/actions/blogAction';

const CreateBlog = () => {
    const dispatch = useDispatch();

    const { success, loading, error, message: successMessage } = useSelector(state => state.newBlog);

    const editor = useRef(null);
    const [content, setContent] = useState("");
    const [file, setFile] = useState(null);
    const [blogVal, setBlogVal] = useState({
        title: "",
        orientation: "",
        visibility: "visible",
        category: ""
    });

    const onChangeHandler = (e) => {
        setBlogVal({ ...blogVal, [e.target.name]: e.target.value });
    }

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const submitHandller = (e) => {
        e.preventDefault();

        if (!blogVal.title) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Title is mandatory!"
            });
        };

        if (!blogVal.orientation) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Orientation is mandatory!"
            });
        };

        if (!content) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Description is mandatory!"
            });
        };

        if (!blogVal.visibility) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Visibility is mandatory!"
            });
        };

        if (!blogVal.category) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Category is mandatory!"
            });
        };

        if (file === null) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please choose thumbnail!"
            });
        }

        if ((file.size / 1024) >= 500) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "File size should be less than '500kb'!"
            });
        };

        const myForm = new FormData();
        myForm.append("title", blogVal.title);
        myForm.append("orientation", blogVal.orientation);
        myForm.append("description", content);
        myForm.append("visibility", blogVal.visibility);
        myForm.append("category", blogVal.category);
        myForm.append("thumbnail", file);

        dispatch(createBlog(myForm));
    }

    useEffect(() => {
        if (error) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
        }

        if (success) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: successMessage
            });
            dispatch(resetNew());
            setFile(null);
            setBlogVal({
                title: "",
                orientation: "",
            });
            setContent("");
            dispatch(getBlog());
        }
    }, [dispatch, success, error, successMessage]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <div className="dashboard-forms">
                <h4>ADD BLOG</h4>
                {
                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                }
                <form>
                    <div className="input-group">
                        <label className="dashboard-label">Title</label>
                        <input
                            type="text"
                            className="dashboard-input"
                            placeholder="Enter blog title"
                            name="title"
                            value={blogVal.title}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Orientation</label>
                        <input
                            type="number"
                            className="dashboard-input"
                            placeholder="Enter blog orientation"
                            name="orientation"
                            value={blogVal.orientation}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Description</label>
                        <JoditEditor
                            ref={editor}
                            value={content}
                            tabIndex={1}
                            onChange={newContent => setContent(newContent)}
                        />
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Visibility <span>(Alert! - This will use to hide and show the blog to viewer!)</span></label>
                        <select
                            className="dashboard-input"
                            name="visibility"
                            value={blogVal.visibility}
                            onChange={(e) => onChangeHandler(e)}
                        >
                            <option>CHOOSE VISIBILITY</option>
                            <option value="visible">Visible</option>
                            <option value="hidden">Hidden</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label className="dashboard-label">Category</label>
                        <select
                            className="dashboard-input"
                            name="category"
                            value={blogVal.category}
                            onChange={(e) => onChangeHandler(e)}
                        >
                            <option>CHOOSE CATEGORY</option>
                            <option value="Cooperative">Cooperative</option>
                            <option value="Literature">Literature</option>
                            <option value="Training Tips">Training Tips</option>
                            <option value="Motivation">Motivation</option>
                            <option value="Interview">Interview</option>
                            <option value="Social/Economic/Political">Social/Economic/Political</option>
                            <option value="Personal">Personal</option>
                        </select>
                    </div>
                    <div className="input-group-type-file">
                        <label className="dashboard-label">Thumbnail <span>(Alert! - File size should be less then "500kb")</span></label>
                        <input
                            type="file"
                            className="dashboard-input-type-file"
                            accept="image/*"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </div>
                    {
                        file && file
                            ?
                            <div className="form-img-preview">
                                <img src={URL.createObjectURL(file)} draggable={false} alt="preview" />
                                <div className="file-remove-btn">
                                    <button onClick={() => setFile(null)}>REMOVE <i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            :
                            <div className="form-img-preview">
                                <img src={img_pre} draggable={false} alt="preview" />
                            </div>
                    }
                    <div className="dashboard-submit-btn100">
                        <button onClick={submitHandller}>ADD</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CreateBlog