import {
    CREATE_CONTACT_REQUEST,
    CREATE_CONTACT_SUCCESS,
    CREATE_CONTACT_FAIL,
    GET_CONTACT_REQUEST,
    GET_CONTACT_SUCCESS,
    GET_CONTACT_FAIL,
    DETAIL_CONTACT_REQUEST,
    DETAIL_CONTACT_SUCCESS,
    DETAIL_CONTACT_FAIL,
    DELETE_CONTACT_REQUEST,
    DELETE_CONTACT_SUCCESS,
    DELETE_CONTACT_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
    RESET_DETAIL
} from '../constants/contactConstant';

export const contactsReducer = (state = { contacts: [] }, action) => {
    switch (action.type) {
        case GET_CONTACT_REQUEST:
            return {
                loading: true,
                contacts: []
            };

        case GET_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                contacts: action.payload.data.data
            };

        case GET_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const newContactReducer = (state = { CONTACT: {} }, action) => {
    switch (action.type) {
        case CREATE_CONTACT_REQUEST:
            return {
                loading: true,
            };

        case CREATE_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RESET_NEW:
            return {
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const contactDetailReducer = (state = { contact: {} }, action) => {
    switch (action.type) {
        case DETAIL_CONTACT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_CONTACT_SUCCESS:
            return {
                loading: false,
                contact: action.payload.data.data
            };

        case DETAIL_CONTACT_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case RESET_DETAIL:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};

export const contactReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_CONTACT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.data.success,
                message: action.payload.data.message
            };

        case DELETE_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case RESET_DELETE:
            return {
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};