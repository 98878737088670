import {
    GET_WEBINFO_REQUEST,
    GET_WEBINFO_SUCCESS,
    GET_WEBINFO_FAIL,
    CREATE_TRAFIC_REQUEST,
    CREATE_TRAFIC_SUCCESS,
    CREATE_TRAFIC_FAIL,
} from '../constants/webInfoConstant';

export const webInfoReducer = (state = { webInfo: [] }, action) => {
    switch (action.type) {
        case GET_WEBINFO_REQUEST:
            return {
                loading: true,
                webInfo: []
            };

        case GET_WEBINFO_SUCCESS:
            return {
                ...state,
                loading: false,
                webInfo: action.payload.data
            };

        case GET_WEBINFO_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            
        default:
            return state;
    }
};

export const newTraficReducer = (state = { trafic: {} }, action) => {
    switch (action.type) {
        case CREATE_TRAFIC_REQUEST:
            return {
                loading: true,
            };

        case CREATE_TRAFIC_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.data.success,
                message: action.payload.data.message
            };

        case CREATE_TRAFIC_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};