import React from 'react'
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb'
import Blog from '../Blog'

const BlogPage = () => {
  return (
    <>
        <Breadcrumb title="Blogs" />
        <Blog />
    </>
  )
}

export default BlogPage