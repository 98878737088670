import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOAD_REQUEST,
    LOAD_SUCCESS,
    LOAD_FAIL,
    LOGOUT_FAIL,
    PASSWORD_REQUEST,
    PASSWORD_SUCCESS,
    PASSWORD_FAIL,
    CLEAR_ERRORS,
    RESET_PASSWORD
} from '../constants/userConstant';

export const userReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
        case LOAD_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
            };
        case LOGIN_SUCCESS:
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload.data.user,
            };

        case LOGIN_FAIL:
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: action.payload,
            };

        case LOGOUT_SUCCESS:
            return {
                loading: false,
                user: null,
                isAuthenticated: false,
            };

        case LOGOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}

export const passwordReducer = (state = { password: {} }, action) => {
    switch (action.type) {
        case PASSWORD_REQUEST:
            return {
                loading: true,
                success: false,
            };
        case PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.payload.data.message
            };

        case PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        case RESET_PASSWORD:
            return {
                loading: false,
                success: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
}