import React from 'react'
import About from './About'
import Banner from './Banner'
import Blog from './Blog'
import Contact from './Contact'

const Home = () => {
  return (
    <>
        <Banner />
        <About />
        <Blog />
        <Contact />
    </>
  )
}

export default Home