import { TOGGLE_SUCCESS } from "../constants/frontendConstant";

export const frontendReducer = (state = { toggle: false }, action) => {
    switch (action.type) {

        case TOGGLE_SUCCESS:
            return {
                toggle: action.payload
            };

        default:
            return state;
    }
};