import React from 'react';
import { data } from '../../../system';
import './system.css';

const System = () => {
    return (
        <div className="system-wrapper">
        <h3>System Information </h3>
            <ul>
                <li><p>Version -</p>{data.version}</li>
                <li><p>Last Update Date -</p>{data.last_update_date}</li>
                <li><p>Security -</p>{data.security}</li>
                <li><p>Security Status -</p><span className="bg-green">{data.security_status}</span></li>
                <li><p>System Uses -</p><span className="bg-orange">{data.system_uses}</span></li>
                <li><p>External Threat -</p><span className="bg-green">{data.external_threat}</span></li>
                <li><p>Registered Admin -</p>{data.registered_admin}</li>
                <li><p>Admin Count -</p>{data.registered_admin_count}</li>
                <li><p>Monitor -</p><span className="bg-green">{data.monitor}</span></li>
                <li><p>System State -</p>{data.system_state}</li>
            </ul>
        </div>
    )
}

export default System