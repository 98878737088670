import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import NotFound from '../../pages/dashboard/notfound/NotFound';
import { getBlog } from '../../states/actions/blogAction';

const Blog = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { loading, blogs } = useSelector(state => state.blogs);

    const [isParams, setIsParams] = useState("");

    useMemo(() => {
        const queryParam = new URLSearchParams(location.search)
        const singleVal = queryParam.get("catVal");
        return setIsParams(singleVal);
    }, [location.search]);

    useEffect(() => {
        dispatch(getBlog());
    }, [dispatch]);
    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="blog-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="global-heading">
                                <h3>BLOGS</h3>
                                <p>My Recent Blogs</p>
                                {
                                    isParams === null ? <></> : <h4>Category: {isParams}</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-4">
                    {
                        isParams === null
                            ?
                            <div className="row">
                                {
                                    blogs && blogs.length <= 0
                                        ?
                                        <NotFound message="Blog not found!" />
                                        :
                                        <>
                                            {
                                                blogs && blogs.filter(opt => opt.visibility === "visible").map((data, index) => (
                                                    <div className="col-md-4" key={index}>
                                                        <Link to={`/blogDetail/${data.id}`}>
                                                            <div className="blog-card">
                                                                <div className="blog-image">
                                                                    <img src={data.image} alt="bhim_bahadur_blog" />
                                                                    <div className="blog-date-time">
                                                                        <h3>{moment(data.created_at).format('Do')}</h3>
                                                                        <p>{moment(data.created_at).format('MMM')}</p>
                                                                        <h4>{moment(data.created_at).format('YYYY')}</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="blog-short-desc">
                                                                    <h2>{data.title}</h2>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))
                                            }
                                        </>
                                }
                            </div>
                            :
                            <>
                                <div className="row">
                                    {
                                        blogs && blogs.length <= 0
                                            ?
                                            <NotFound message="Blog not found!" />
                                            :
                                            <>
                                                {
                                                    blogs && blogs.filter(opt => opt.visibility === "visible").filter(opt => opt.category === isParams).map((data, index) => (
                                                        <div className="col-md-4" key={index}>
                                                            <Link to={`/blogDetail/${data.id}`}>
                                                                <div className="blog-card">
                                                                    <div className="blog-image">
                                                                        <img src={data.image} alt="bhim_bahadur_blog" />
                                                                        <div className="blog-date-time">
                                                                            <h3>{moment(data.created_at).format('Do')}</h3>
                                                                            <p>{moment(data.created_at).format('MMM')}</p>
                                                                            <h4>{moment(data.created_at).format('YYYY')}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="blog-short-desc">
                                                                        <h2>{data.title}</h2>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                    }
                                </div>
                            </>
                    }
                </div>
            </section>
        </>
    )
}

export default Blog;