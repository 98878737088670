import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../loader/Loader';
import { toggleAction } from '../../states/actions/frontendAction';
import { getBlog } from '../../states/actions/blogAction';

const Navigation = () => {
    const dispatch = useDispatch();

    const { toggle } = useSelector(state => state.frontend);
    const { loading, blogs } = useSelector(state => state.blogs);

    const onClickHandler = (data) => {
        return dispatch(toggleAction(data));
    }

    useEffect(() => {
        dispatch(getBlog());
    }, [dispatch]);

    return (
        <>
            {loading ? <Loader /> : <></>}
            <section className="navigation-section navigation_bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12 col-lg-12">
                            <div className="navigation-parent-wrapper">
                                <div className="navigation-childs-wrapper">
                                    <div className="bhim_bahadur_gurung">
                                        <h3><Link to="/">BhimGurung</Link></h3>
                                    </div>
                                </div>
                                <div className="navigation-childs-wrapper">
                                    <div className={toggle ? "menu leftVal0" : "menu"}>
                                        <ul>
                                            <li onClick={() => onClickHandler(false)}><Link to="/">Home</Link></li>
                                            <li onClick={() => onClickHandler(false)}><Link to="/about">About</Link></li>
                                            <li onClick={() => onClickHandler(false)}><Link to="/blog">Blog</Link></li>
                                            {
                                                blogs && blogs.filter(opt => opt.visibility === "visible").slice(0, 2).map((data, index) => (
                                                    <li onClick={() => onClickHandler(false)} key={index}><Link to={`/blog?catVal=${data.category}`}>{data.category}</Link></li>
                                                ))
                                            }
                                            <li className="submenu-includes">
                                                <Link to="/contact">More <i className="fa fa-angle-down"></i>
                                                    <div className="sub-menu">
                                                        {
                                                            blogs && blogs.filter(opt => opt.visibility === "visible").filter((v, i, a) => a.findIndex(v2 => (v2.category === v.category)) === i)
                                                                .map((data, index) => (
                                                                    <ol key={index} onClick={() => onClickHandler(false)}>
                                                                        <Link to={`/blog?catVal=${data.category}`}><i className="fa fa-angle-right"></i> {data.category}</Link>
                                                                    </ol>
                                                                ))
                                                        }
                                                    </div>
                                                </Link></li>
                                            <li onClick={() => onClickHandler(false)}><Link to="/contact">Contact</Link></li>
                                        </ul>
                                        <div className="toggle-navigation-close"><i onClick={() => onClickHandler(false)} className="fa fa-times"></i></div>
                                    </div>
                                    <div className="toggle-navigation"><i onClick={() => onClickHandler(true)} className="fa fa-bars"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Navigation;