import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/styles.scss';
import './scss/fonts/Poppins-Regular.ttf';
import './scss/fonts/Poppins-ThinItalic.ttf';
import ProtectedRoutes from './components/protectedRoutes/ProtectedRoutes';
import Dashboard from './pages/dashboard/Dashboard';
import App from './App';
import ScrollToTop from './components/scrollManager/Scroll';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <HashRouter>
    <Provider store={store}>
      <div className="bhim_bahadur_gurung_master_wrapper">
      <ScrollToTop />
        <Routes>
          <Route path="/*" element={<App />} exact />
          <Route element={<ProtectedRoutes />}>
            <Route path="/dashboard/*" element={<Dashboard />} />
          </Route>
        </Routes>
      </div>
    </Provider>
  </HashRouter>
  // </React.StrictMode>
);
