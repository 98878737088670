export const FETCH_UPLOAD_REQUEST = "FETCH_UPLOAD_REQUEST";
export const FETCH_UPLOAD_SUCCESS = "FETCH_UPLOAD_SUCCESS";
export const FETCH_UPLOAD_FAIL = "FETCH_UPLOAD_FAIL";

export const CREATE_UPLOAD_REQUEST = "CREATE_UPLOAD_REQUEST";
export const CREATE_UPLOAD_SUCCESS = "CREATE_UPLOAD_SUCCESS";
export const CREATE_UPLOAD_FAIL = "CREATE_UPLOAD_FAIL";

export const DELETE_UPLOAD_REQUEST = "DELETE_UPLOAD_REQUEST";
export const DELETE_UPLOAD_SUCCESS = "DELETE_UPLOAD_SUCCESS";
export const DELETE_UPLOAD_FAIL = "DELETE_UPLOAD_FAIL";

export const RESET_NEW = "RESET_NEW";
export const RESET_DELETE = "RESET_DELETE";
export const RESET_DETAIL = "RESET_DETAIL";
export const RESET_UPDATE = "RESET_UPDATE";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
