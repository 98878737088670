import React from 'react'
import Breadcrumb from '../../../components/breadcrumb/Breadcrumb'
import Contact from '../Contact'

const ContactPage = () => {
  return (
    <>
        <Breadcrumb title="Contact" />
        <Contact />
    </>
  )
}

export default ContactPage