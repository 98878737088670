import {
    CREATE_BLOG_REQUEST,
    CREATE_BLOG_SUCCESS,
    CREATE_BLOG_FAIL,
    GET_BLOG_REQUEST,
    GET_BLOG_SUCCESS,
    GET_BLOG_FAIL,
    DETAIL_BLOG_REQUEST,
    DETAIL_BLOG_SUCCESS,
    DETAIL_BLOG_FAIL,
    DELETE_BLOG_REQUEST,
    DELETE_BLOG_SUCCESS,
    DELETE_BLOG_FAIL,
    UPDATE_BLOG_REQUEST,
    UPDATE_BLOG_SUCCESS,
    UPDATE_BLOG_FAIL,
    CLEAR_ERRORS,
    RESET_NEW,
    RESET_DELETE,
    RESET_DETAIL,
    RESET_UPDATE,
} from '../constants/blogConstant';
import axios from 'axios';

const globalLink = process.env.REACT_APP_API_URL;

// get blog::begin
export const getBlog = () => async (dispatch) => {
    try {
        dispatch({ type: GET_BLOG_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/blog/getBlog`, config);

        dispatch({ type: GET_BLOG_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: GET_BLOG_FAIL, payload: error.response.data.message });
    }
}
// get blog::end

// create blog::begin
export const createBlog = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_BLOG_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.post(`${globalLink}/api/blog/createBlog`, data, config);

        dispatch({ type: CREATE_BLOG_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: CREATE_BLOG_FAIL, payload: error.response.data.message });
    }
}
// create blog::end

// delete blog::begin
export const deleteBlog = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_BLOG_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.delete(`${globalLink}/api/blog/deleteBlog/${id}`, config);

        dispatch({ type: DELETE_BLOG_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DELETE_BLOG_FAIL, payload: error.response.data.message });
    }
}
// delete blog::end

// detail blog::begin
export const detailBlog = (id) => async (dispatch) => {
    try {
        dispatch({ type: DETAIL_BLOG_REQUEST });

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const res = await axios.get(`${globalLink}/api/blog/detailBlog/${id}`, config);

        dispatch({ type: DETAIL_BLOG_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: DETAIL_BLOG_FAIL, payload: error.response.data.message });
    }
}
// detail blog::end

// update blog::begin
export const updateBlog = (data, id) => async (dispatch) => {
    console.log([...data])
    try {
        dispatch({ type: UPDATE_BLOG_REQUEST });

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        };

        const res = await axios.put(`${globalLink}/api/blog/updateBlog/${id}`, data, config);

        dispatch({ type: UPDATE_BLOG_SUCCESS, payload: res });
    } catch (error) {
        dispatch({ type: UPDATE_BLOG_FAIL, payload: error.response.data.message });
    }
};
// update blog::end

// resets::begin
export const resetNew = () => async (dispatch) => {
    dispatch({ type: RESET_NEW });
};

export const resetDelete = () => async (dispatch) => {
    dispatch({ type: RESET_DELETE });
};

export const resetDetail = () => async (dispatch) => {
    dispatch({ type: RESET_DETAIL });
};

export const resetUpdate = () => async (dispatch) => {
    dispatch({ type: RESET_UPDATE });
};
// resets::end

// clearError::begin
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
// clearError::end