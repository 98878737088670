import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import img_pre from '../../../assets/images/designs/img-pre.png';
import Notify from '../notify/Notify';
import Loader from '../../../components/loader/Loader';
import { clearErrors, createUpload, getUpload, resetNew } from '../../../states/actions/uploadAction';

const CreateUpload = () => {
    const dispatch = useDispatch();

    const { loading, success, upload, error } = useSelector((state) => state.newUpload);

    const [file, setFile] = useState(null);

    const [isMessage, setIsMessage] = useState({
        isThereMessage: false,
        messageType: "",
        message: ""
    });

    const closeMessage = () => {
        return setIsMessage({
            isThereMessage: false,
            messageType: "",
            message: ""
        });
    }

    const submitHandler = (e) => {
        e.preventDefault();

        if (file === null) {
            return setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: "Please select file!"
            });
        }

        const myForm = new FormData();
        myForm.append("image", file);

        dispatch(createUpload(myForm));
    }

    useEffect(() => {
        if (error) {
            setIsMessage({
                isThereMessage: true,
                messageType: "danger",
                message: error
            });
            dispatch(clearErrors());
        }

        if (success) {
            setIsMessage({
                isThereMessage: true,
                messageType: "success",
                message: upload.message
            });
            dispatch(getUpload());
            dispatch(resetNew());
            setFile(null);
        };
    }, [dispatch, success, upload.message, error]);

    return (
        <>
             {loading ? <Loader /> : <></>}
            <div className="dashboard-forms">
                <h4>CREATE ONLINE LINK</h4>
                {
                    isMessage.isThereMessage ? <Notify message={isMessage.message} type={isMessage.messageType} closeMessage={closeMessage} /> : <></>
                }
                <form>
                    <div className="input-group-type-file">
                        <label className="dashboard-label">Upload Image <span>(Alert! - Please use less than 500kb file for quick load!)</span></label>
                        <input
                            type="file"
                            className="dashboard-input-type-file width100"
                            accept="image/*"
                            onChange={(e) => setFile(e.target.files[0])}
                        />
                    </div>
                    {
                        file && file
                            ?
                            <div className="form-img-preview">
                                <img src={URL.createObjectURL(file)} draggable={false} alt="preview" />
                                <div className="file-remove-btn">
                                    <button onClick={() => setFile(null)}>REMOVE <i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            :
                            <div className="form-img-preview">
                                <img src={img_pre} draggable={false} alt="preview" />
                            </div>
                    }
                    <div className="dashboard-submit-btn100">
                        <button onClick={submitHandler}>ADD</button>
                    </div>
                </form>
            </div>

        </>
    )
}

export default CreateUpload;